<template>
  <div :class="['Checkbox-input', { 'Checkbox-input--disabled': disabled }]">
    <div class="Chekbox-input__input-container">
      <input
        :aria-describedby="description ? `${description}-helper-text` : undefined"
        :aria-labelledby="labelledby ?? `checkbox-input-${id}`"
        :disabled="disabled"
        :id="id"
        :value="value"
        class="Checkbox-input__input"
        type="checkbox"
        v-model="model"
      />
      <span class="Checkbox-input__sub">
        <ux-atoms-icon class="Checkbox-input__icon" name="checkbox" size="xs" />
      </span>
    </div>
    <ux-atoms-typo :for="id" as="label" class="Checkbox-input__label" variant="text-small">
      <span class="accessibility-sr-only" v-if="srTheme">{{ srTheme }} : </span>{{ label }}
      <slot></slot>
    </ux-atoms-typo>
  </div>
</template>

<script setup lang="ts">
export interface CheckboxInputProps {
  description?: string;
  disabled?: boolean;
  label?: string;
  labelledby?: string;
  modelValue?: boolean | string[];
  srTheme?: string;
  value?: number | string;
}

const props = withDefaults(defineProps<CheckboxInputProps>(), {
  description: undefined,
  disabled: false,
  label: undefined,
  labelledby: undefined,
  modelValue: undefined,
  srTheme: undefined,
  value: undefined
});

const id = useId();

const model = useVModel(props, 'modelValue');
</script>

<style lang="scss" scoped>
@use 'CheckboxInput.scss';
</style>
